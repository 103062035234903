<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title
        >{{ data.name ? data.name : "Новый" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <v-row>
        <v-col cols="12">
          <a-form-model v-model="dataEdit" :model="model" :errors="errors" @validate="validate($event)" />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="_submit()"></a-btn-save>
        <a-btn-delete v-if="id && false" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl } from "@/components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl],
  props: {
    value: Boolean,
    id: [Number, String],
    model: Array,
    api: String,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      removeDialogShow: false,
      dataEdit: {},
    };
  },
  computed: {},
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
        this.data.object_id = this.object_id;
      }
    },
  },
  methods: {
    async fetchData(id) {
      if (id) {
        this.loading = true;
        let response = await this.$axios.get(this.api);
        let obj = response.data.data;
        let data;
        if (Object.hasOwnProperty.call(obj, id)) {
          data = { id, name: id, sqlText: obj[id] };
        }
        for (const name in data) {
          this.$set(this.dataEdit, name, data[name]);
        }
        this.loading = false;
        this.afterFetchData(response);
      } else {
        let data = { id: null, name: null, sqlText: null };
        for (const name in data) {
          this.$set(this.dataEdit, name, data[name]);
        }
      }
    },

    async _submit() {
      let response = await this.$axios.get(this.api);
      if (response.data.status !== "ok") return;
      let data = response.data.data;
      data[this.dataEdit.name] = this.dataEdit.sqlText;
      this.data = { data };

      this.submit();
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>
